const MenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Customers",
        route: "/customers",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-printer",
        forRole: "Admin",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Users",
        route: "/users",
        svgIcon: "media/icons/duotune/communication/com014.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
  {
    pages: [
      {
        svgIcon: "media/icons/duotune/layouts/lay009.svg",
        fontIcon: "bi-printer",
        sectionTitle: "Settings",
        sub: [
          {
            heading: "Meta data",
            route: "/enumerations",
          },
          {
            heading: "User Levels",
            route: "/userlevel",
          },
          {
            heading: "PlaylistRule",
            route: "/playlistrule",
          },
          {
            route: "/pagelayout",
            heading: "Page Layout",
          },
          {
            heading: "Overviews",
            route: "/overviews",
          }
        ],
      },
    ],
  },
  // {
  // 	pages: [
  // 		{
  // 			heading: 'Articles',
  // 			route: '/articles',
  // 			svgIcon: 'media/icons/duotune/layouts/lay009.svg',
  // 			fontIcon: 'bi-printer'
  // 		}
  // 	]
  // },
  {
    pages: [
      {
        svgIcon: "media/icons/duotune/layouts/lay009.svg",
        fontIcon: "bi-printer",
        sectionTitle: "Template",
        forRole: "Admin",
        sub: [
          {
            heading: "Content",
            route: "/template/Page",
          },
          {
            heading: "Component",
            route: "/template/Component",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        svgIcon: "media/icons/duotune/layouts/lay009.svg",
        fontIcon: "bi-printer",
        sectionTitle: "Content",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Component",
        route: "/templatecontent/Component",
        svgIcon: "media/icons/duotune/layouts/lay009.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Video Content",
        route: "/videocontent",
        svgIcon: "media/icons/duotune/layouts/lay011.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Playlist",
        route: "/playlist",
        svgIcon: "media/icons/duotune/layouts/lay012.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
  // {
  // 	pages: [
  // 		{
  // 			svgIcon: 'media/icons/duotune/layouts/lay013.svg',
  // 			fontIcon: 'bi-printer',
  // 			sectionTitle: 'Page Layout',
  // 			sub: [
  // 				{
  // 					heading: 'Dynamic Content',
  // 					route: '/pagelayout'
  // 				}
  // 			]
  // 		}
  // 	]
  // },
  {
    pages: [
      {
        heading: "Price Plan",
        route: "/priceplan",
        svgIcon: "media/icons/duotune/ecommerce/ecm003.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
  {
    pages: [
      {
        heading: "Vouchers",
        route: "/vouchers",
        svgIcon: "media/icons/duotune/ecommerce/ecm003.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
  {
    pages: [
      {
        heading: "CMS Media",
        route: "/cmsmedia",
        svgIcon: "media/icons/duotune/general/gen006.svg",
        fontIcon: "bi-printer",
      },
    ],
  },
];

export default MenuConfig;
