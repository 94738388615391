import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "menu-title position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.customerList?.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.customerList, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${i}${_ctx.selectedCustomer}`,
            class: "menu-item px-5",
            "data-kt-menu-trigger": "click",
            "data-kt-menu-placement": "left-start",
            "data-kt-menu-flip": "center, top"
          }, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["menu-link px-5", { active: _ctx.currentCustomer(item.id) }]),
              onClick: ($event: any) => (_ctx.setCurrentCustomer(item.id))
            }, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1)
            ], 10, _hoisted_2)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}