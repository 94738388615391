
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import KTTopbar from '@/layout/header/Topbar.vue';
import KTToolbar from '@/layout/toolbar/Toolbar.vue';

import {
	subheaderDisplay,
	headerWidthFluid,
	headerLeft
	// asideDisplay
} from '@/core/helpers/config';

export default defineComponent({
	name: 'KTHeader',
	props: {
		title: String
	},
	components: {
		KTTopbar,
		KTToolbar
	},
	setup() {
		const store = useStore();

		const pageTitle = computed(() => {
			return store.getters.pageTitle;
		});

		const breadcrumbs = computed(() => {
			return store.getters.pageBreadcrumbPath;
		});

		return {
			pageTitle,
			headerLeft,
			breadcrumbs,
			subheaderDisplay,
			headerWidthFluid
			// asideDisplay
		};
	}
});
