
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import KTUserMenu from '@/layout/header/partials/UserMenu.vue';
import CustomerMenu from '@/layout/header/partials/CustomerMenu.vue';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import ApiService from '@/core/services/ApiService';
import { ICustomer } from '@/core/data/customers';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'topbar',
	components: {
		KTUserMenu,
		CustomerMenu
	},
	setup() {
		const store = useStore();
		const customerList = ref<Array<ICustomer>>([]);
		const selectedCustomerName = ref('Select Customer...');

		const { role } = store.getters.currentUser;

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		watch(selectedCustomer, (currentCustomer, oldCustomer) => {
			if (currentCustomer && customerList) {
				const customerObj = customerList?.value?.find(
					item => item.id == selectedCustomer.value
				)?.name;
				selectedCustomerName.value = customerObj || 'Select Customer...';
			}
		});

		const getCustomersList = () => {
			ApiService.get('Customer')
				.then(({ data }) => {
					customerList.value.splice(0, customerList.value.length, ...data);
					if (selectedCustomer) {
						const customerObj = customerList.value.find(
							item => item.id == selectedCustomer.value
						)?.name;
						selectedCustomerName.value = customerObj || 'Select Customer...';
					}
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		onMounted(() => {
			if (role.toLowerCase() === 'admin') {
				getCustomersList();
			}
		});

		return {
			role,
			customerList,
			selectedCustomer,
			selectedCustomerName
		};
	}
});
