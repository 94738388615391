
import { defineComponent, onMounted, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n/index';
import KTSubMenu from '@/layout/aside/SubMenu.vue';
// import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import CleanMainMenuConfig from '@/core/config/CleanMainMenuConfig';

export default defineComponent({
	name: 'kt-menu',
	components: {
		KTSubMenu
	},
	setup() {
		const { t, te } = useI18n();
		const store = useStore();
		const route = useRoute();
		const role = store.getters.currentUser['role'];
		const scrollElRef = ref<null | HTMLElement>(null);
		const templateSubmenu = ref<Array<{ heading: string; route: string }>>([]);
		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 100
			},
			filter: {
				searchTerm: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		watch(
			() => selectedCustomer.value,
			(newValue, oldValue) => {
				setTimeout(() => {
					getTemplateList();
				}, 1000);
			},
			{ deep: true }
		);

		const menuRoutes = <Array<any>>CleanMainMenuConfig;
		const roleBasedMenu = menuRoutes.filter(item => {
			if (item.pages[0].forRole) {
				if (item.pages[0].forRole === role) {
					return item;
				} else {
					return;
				}
			}
			return item;
		});

		const getTemplateList = () => {
			axios
				.post(`Template/list/Page`, pagingFilteration.value, {
					headers: {
						Authorization: `Bearer ${JwtService.getToken()}`
					}
				})
				.then(({ data }) => {
					const newTemSubMenu = data.map(item => ({
						heading: item.contentName,
						route: `/templatecontent/${item.templateId}`
					}));
					templateSubmenu.value = newTemSubMenu;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		onMounted(() => {
			getTemplateList();
			if (scrollElRef.value) {
				scrollElRef.value.scrollTop = 0;
			}
		});

		const translate = text => {
			if (te(text)) {
				return t(text);
			} else {
				return text;
			}
		};

		const hasActiveChildren = match => {
			return route.path.indexOf(match) !== -1;
		};

		return {
			hasActiveChildren,
			roleBasedMenu,
			asideMenuIcons,
			version,
			translate,
			templateSubmenu
		};
	}
});
