
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n/index';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Actions } from '@/store/enums/StoreEnums';

export default defineComponent({
	name: 'kt-user-menu',
	components: {},
	setup() {
		const router = useRouter();
		const i18n = useI18n();
		const store = useStore();
		const { userName } = store.getters.currentUser;

		i18n.locale.value = localStorage.getItem('lang')
			? (localStorage.getItem('lang') as string)
			: 'en';

		const countries = {
			en: {
				flag: 'media/flags/united-states.svg',
				name: 'English'
			},
			es: {
				flag: 'media/flags/spain.svg',
				name: 'Spanish'
			},
			de: {
				flag: 'media/flags/germany.svg',
				name: 'German'
			},
			ja: {
				flag: 'media/flags/japan.svg',
				name: 'Japanese'
			},
			fr: {
				flag: 'media/flags/france.svg',
				name: 'French'
			}
		};

		const signOut = () => {
			store
				.dispatch(Actions.LOGOUT)
				.then(() => router.push({ name: 'sign-in' }));
		};

		const setLang = lang => {
			localStorage.setItem('lang', lang);
			i18n.locale.value = lang;
		};

		const currentLanguage = lang => {
			return i18n.locale.value === lang;
		};

		const currentLangugeLocale = computed(() => {
			return countries[i18n.locale.value];
		});

		return {
			signOut,
			setLang,
			currentLanguage,
			currentLangugeLocale,
			countries,
			userName
		};
	}
});
