
import { useStore } from 'vuex';
import { defineComponent, computed, ref, watch } from 'vue';
import { Actions } from '@/store/enums/StoreEnums';

export default defineComponent({
	name: 'kt-customer-menu',
	props: {
		customerList: Array
	},
	components: {},
	setup(props) {
		const store = useStore();

		const customerList = ref(props.customerList);

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const setCurrentCustomer = id => {
			store.dispatch(Actions.SWITCH_CUSTOMER, id).then(() => {
				window.location.href='/'
			});
		};

		const currentCustomer = id => {
			return selectedCustomer.value == id;
		};

		return {
			setCurrentCustomer,
			currentCustomer,
			selectedCustomer,
			customerList
		};
	}
});
