
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n/index';

export default defineComponent({
	name: 'kt-sub-menu',
	props: {
		subMenu: Array
	},
	components: {},
	setup(props) {
		const { t, te } = useI18n();
		const route = useRoute();

		const subMenu = computed(() => {
			return props.subMenu;
		});

		const translate = text => {
			if (te(text)) {
				return t(text);
			} else {
				return text;
			}
		};

		const hasActiveChildren = match => {
			return route.path.indexOf(match) !== -1;
		};

		return {
			hasActiveChildren,
			translate,
			subMenu
		};
	}
});
